import * as React from "react";
import { useMemo } from "react";

import { DatoImage } from "@byko/component-dato-image";
import { TextBlockVariantOne } from "@byko/text-block-variant-one";

import {
  BlockCard,
  CardContainer,
  Container,
  PrimaryImageContainer,
  SecondaryImageContainer,
  SecondaryImageContainerWrapper,
} from "./styles";

import type { ContentSectionVariantTwoProps } from "./interface";

export const ContentSectionVariantTwo = ({
  heading,
  paragraph,
  headingUnderlineColor = "blueLine",
  introText,
  headingDecoration,
  maxColumns = 1,
  actionButton,
  primaryImage,
  decorativeImageOne,
  decorativeImageTwo,
  decorativeImageThree,
}: ContentSectionVariantTwoProps): JSX.Element => {
  const decorativeImageCount = useMemo((): number => {
    return (
      Number(Boolean(decorativeImageOne)) + Number(Boolean(decorativeImageTwo)) + Number(Boolean(decorativeImageThree))
    );
  }, [decorativeImageOne, decorativeImageThree, decorativeImageTwo]);
  return (
    <Container>
      <PrimaryImageContainer height={primaryImage.height} width={primaryImage.width}>
        <DatoImage {...primaryImage} />
      </PrimaryImageContainer>
      <CardContainer>
        <BlockCard hasDecorativeImage={decorativeImageCount > 0}>
          <TextBlockVariantOne
            buttonLabel={actionButton?.text}
            buttonLink={actionButton?.link}
            heading={heading}
            headingDecoration={headingDecoration}
            headingUnderlineColor={headingUnderlineColor}
            introText={introText}
            maxColumns={maxColumns}
            paragraph={paragraph}
          />

          {decorativeImageCount > 0 && (
            <SecondaryImageContainerWrapper imageCount={decorativeImageCount}>
              {decorativeImageOne ? (
                <SecondaryImageContainer>
                  <DatoImage {...decorativeImageOne} />
                </SecondaryImageContainer>
              ) : null}
              {decorativeImageTwo ? (
                <SecondaryImageContainer>
                  <DatoImage {...decorativeImageTwo} />
                </SecondaryImageContainer>
              ) : null}
              {decorativeImageThree ? (
                <SecondaryImageContainer>
                  <DatoImage {...decorativeImageThree} />
                </SecondaryImageContainer>
              ) : null}
            </SecondaryImageContainerWrapper>
          )}
        </BlockCard>
      </CardContainer>
    </Container>
  );
};
