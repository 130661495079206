import * as React from "react";

import { DatoImage } from "@byko/component-dato-image";
import { TextBlockVariantOne } from "@byko/text-block-variant-one";

import { BlockCard, CardContainer, Container, PrimaryImageContainer } from "./styles";

import type { ContentSectionVariantOneProps } from "./interface";

export const ContentSectionVariantOne = ({
  actionButton,
  heading,
  paragraph,
  headingUnderlineColor = "blueLine",
  headingDecoration,
  introText,
  maxColumns = 1,
  primaryImage,
  mirrorY = false,
}: ContentSectionVariantOneProps): JSX.Element => {
  return (
    <Container mirrorY={mirrorY}>
      <CardContainer>
        <BlockCard>
          <TextBlockVariantOne
            buttonLabel={actionButton?.text}
            buttonLink={actionButton?.link}
            heading={heading}
            headingDecoration={headingDecoration}
            headingUnderlineColor={headingUnderlineColor}
            introText={introText}
            maxColumns={maxColumns}
            paragraph={paragraph}
          />
        </BlockCard>
      </CardContainer>
      <PrimaryImageContainer>
        <DatoImage {...primaryImage} />
      </PrimaryImageContainer>
    </Container>
  );
};
