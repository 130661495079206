import styled from "styled-components";

import { gridTheme } from "@byko/lib-styles";

import type { BlockProps, DecorativeImageContainerProps } from "./interface";

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  padding-top: 48px;
  margin-right: auto;
  margin-left: auto;
`;

export const Block = styled.div<BlockProps>`
  display: grid;
  width: fit-content;
  align-items: center;
  grid-column-gap: ${(props): string => (props.mirrorX ? "129px " : "129px")};
  grid-template-areas: ${(props): string => (props.mirrorX ? `"images textblock"` : `"textblock images"`)};
  grid-template-columns: ${(props): string => (props.mirrorX ? "6fr 4fr" : "4fr 6fr")};

  @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    grid-gap: 10px;
    grid-template-areas:
      "images"
      "textblock";
    grid-template-columns: 1fr;
  }
`;

export const TextBlockContainer = styled.div<BlockProps>`
  padding-top: 24px;
`;

export const DecorativeImageContainer = styled.div<DecorativeImageContainerProps>`
  position: relative;
  left: 124px;
  display: flex;
  width: calc(100% - 124px);
  height: 100%;
  flex-direction: column;
  row-gap: 16px;
  @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    left: 0;
    width: 100%;
    height: 330px;
    margin-top: 48px;
  }
  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
      height: 480px;
    }
    @media screen and (max-width: ${gridTheme.breakpoints.xs}px) {
      height: 250px;
    }
  }
`;

export const ImageContainer = styled.div<BlockProps>`
  height: 100%;
`;

export const Images = styled.div`
  position: relative;
  height: 100%;
  grid-area: images;
`;

export const SmallImageOverlay = styled.div<BlockProps>`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 56px;
  transform: translate3d(0, -48px, 0);

  @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    top: 0;
    left: 16px;
    width: 100%;
    height: unset;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding-right: 8px;
    gap: 8px;
    transform: translate3d(0, 0, 0);
  }
`;

export const SmallImageContainer = styled.div`
  width: 320px;
  height: 320px;
  min-height: 320px;
  filter: drop-shadow(0px 14px 48px rgba(0, 0, 0, 0.16));
  @media screen and (max-width: ${gridTheme.breakpoints.md}px) {
    width: 280px;
    height: 280px;
    min-height: 280px;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    width: 200px;
    height: 200px;
    min-height: 200px;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    width: 110px;
    height: 110px;
    min-height: 110px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
`;
