import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  margin-right: auto;
  margin-bottom: 100px;
  margin-left: auto;
`;

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 112px;
  margin-bottom: -330px;
  margin-left: 112px;
  transform: translate3d(0, -230px, 0);
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    top: 120px;
    margin-right: 24px;
    margin-bottom: 24px;
    margin-left: 24px;
    transform: translate3d(0, 0, 0);
  }
`;

export const BlockCard = styled.div<{ hasDecorativeImage: boolean }>`
  display: grid;
  align-items: center;
  padding: 96px 112px;
  background-color: ${theme.palette.white.main};
  box-shadow: 0px 18px 45px ${theme.palette.shadow.main};
  grid-gap: 16px;
  grid-template-columns: ${(props): string => (props.hasDecorativeImage ? "1fr 1fr" : "none")};
  @media screen and (min-width: ${gridTheme.container.minWidth.xs}px) and (max-width: ${gridTheme.container.maxWidth
      .xs}px) {
    padding: 48px 24px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.sm}px) and (max-width: ${gridTheme.container.maxWidth
      .sm}px) {
    padding: 64px 48px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.s}px) and (max-width: ${gridTheme.container.maxWidth
      .s}px) {
    padding: 80px 56px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.md}px) and (max-width: ${gridTheme.container.maxWidth
      .md}px) {
    padding: 80px 56px;
  }
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    grid-template-columns: ${(props): string => (props.hasDecorativeImage ? "1fr" : "none")};
  }
`;

export const PrimaryImageContainer = styled.div<{
  height: number;
  width: number;
}>`
  position: relative;
  width: 100%;
  height: 40vh;
  max-height: 616px;
  aspect-ratio: ${(props): number => props.width} / ${(props): number => props.height};
  img {
    width: 100% !important;
    height: 100% !important;
    max-height: 616px;
    object-fit: cover !important;
  }
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    position: absolute;
    max-height: 480px;
  }
`;

export const SecondaryImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  img {
    object-fit: cover !important;
  }
`;

export const SecondaryImageContainerWrapper = styled.div<{ imageCount: number }>`
  position: relative;
  display: grid;
  height: 100%;
  align-items: center;
  grid-gap: 8px;
  grid-template-rows: repeat(${(props): string => props.imageCount.toString()}, 1fr);
`;
