import * as React from "react";
import { useMemo } from "react";

import { BlockImageLink } from "@byko/block-image-link";
import { DatoImage } from "@byko/component-dato-image";

import {
  Block,
  Container,
  DecorativeImageContainer,
  DecorativeImageContainerWrapper,
  ImageContainer,
  TaggedImageContainer,
  TaggedImageOverlay,
  TextBlock,
  TextBlockContainer,
} from "./styles";

import type { ContentSectionVariantFourProps } from "./interface";

export const ContentSectionVariantFour = ({
  heading,
  paragraph,
  headingUnderlineColor = "blueLine",
  headingDecoration,
  mirrorY = false,
  actionButton,
  introText,
  itemSection,
  decorativeImageOne,
  decorativeImageTwo,
  tagImageOne,
  tagImageTwo,
}: ContentSectionVariantFourProps): JSX.Element => {
  const tagCount = useMemo((): number => {
    return Number(Boolean(tagImageOne)) + Number(Boolean(tagImageTwo));
  }, [tagImageOne, tagImageTwo]);

  return (
    <Container>
      <Block mirrorY={mirrorY}>
        <TextBlockContainer>
          <TextBlock
            buttonLabel={actionButton?.text}
            buttonLink={actionButton?.link}
            heading={heading}
            headingDecoration={headingDecoration}
            headingUnderlineColor={headingUnderlineColor}
            introText={introText}
            itemSection={itemSection}
            paragraph={paragraph}
          />
        </TextBlockContainer>
        <ImageContainer>
          <DecorativeImageContainerWrapper mirrorY={mirrorY} tagCount={tagCount}>
            <DecorativeImageContainer>
              <DatoImage {...decorativeImageOne} />
            </DecorativeImageContainer>
            {decorativeImageTwo && (
              <DecorativeImageContainer>
                <DatoImage {...decorativeImageTwo} />
              </DecorativeImageContainer>
            )}
          </DecorativeImageContainerWrapper>
          <TaggedImageOverlay mirrorY={mirrorY}>
            {tagImageOne && (
              <TaggedImageContainer>
                <BlockImageLink
                  image={tagImageOne.jpeg}
                  label={tagImageOne.title}
                  link={tagImageOne.link}
                  mirrorX={true}
                  noIcon={true}
                />
              </TaggedImageContainer>
            )}
            {tagImageTwo && (
              <TaggedImageContainer>
                <BlockImageLink
                  image={tagImageTwo.jpeg}
                  label={tagImageTwo.title}
                  link={tagImageTwo.link}
                  mirrorX={true}
                  noIcon={true}
                />
              </TaggedImageContainer>
            )}
          </TaggedImageOverlay>
        </ImageContainer>
      </Block>
    </Container>
  );
};
