import * as React from "react";

import { DatoImage } from "@byko/component-dato-image";
import { TextBlockVariantOne } from "@byko/text-block-variant-one";

import { Container, PrimaryImageContainer, TextCard } from "./styles";

import type { ContentSectionVariantSixProps } from "./interface";

export const ContentSectionVariantSix = ({
  heading,
  paragraph,
  headingUnderlineColor = "blueLine",
  headingDecoration,
  mirrorX = false,
  introText,
  primaryImage,
  actionButton,
  isTopContent = false,
}: ContentSectionVariantSixProps): JSX.Element => {
  return (
    <>
      <PrimaryImageContainer>
        <DatoImage {...primaryImage} priority={isTopContent} />
      </PrimaryImageContainer>
      <Container mirrorX={mirrorX}>
        <TextCard>
          <TextBlockVariantOne
            buttonLabel={actionButton?.text}
            buttonLink={actionButton?.link}
            heading={heading}
            headingDecoration={headingDecoration}
            headingSize={isTopContent ? 1 : 2}
            headingUnderlineColor={headingUnderlineColor}
            introText={introText}
            maxColumns={1}
            paragraph={paragraph}
          />
        </TextCard>
      </Container>
    </>
  );
};
