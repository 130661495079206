import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 3000000;
  top: 0;
  right: 48px;
`;

export const SwiperContainer = styled.div`
  position: relative;
  z-index: 3000000;
  width: 100%;
  height: 100%;
  .swiper {
    overflow: unset;
    height: calc(100% - 100px) !important;
  }
`;

export const SliderImage = styled.img`
  position: relative;
  width: auto;
  max-width: 90%;
  height: 100%;
  object-fit: contain;
`;

export const OverlayOuter = styled.button`
  position: fixed;
  z-index: 2000000;
  display: block;
  inset: 0;
  opacity: 0;
  &.fade-in {
    opacity: 0.4;
    transition: 300ms;
  }
`;

export const OverlayContainer = styled.div<{ active: boolean }>`
  position: fixed;
  z-index: 3000000;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;

  background-color: rgba(255, 255, 255, 0.9);
  inset: 122px 32px 32px 32px;
  opacity: 0;
  outline: 2px solid white;
  transition: 300ms;

  &.fade-in {
    opacity: 1;
    transition: 300ms;
  }

  @media screen and (max-width: 1438px) {
    inset: 86px 0px 0px 0px;
  }
  @media screen and (max-width: 768px) {
    padding-right: 16px;
    padding-left: 16px;
    background-color: rgba(255, 255, 255, 1);
    inset: 0px;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  margin-top: 48px;
  margin-right: auto;
  margin-left: auto;
`;

export const AlbumItemContainerBig = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
`;

export const ImageBlock = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  object-fit: cover;
`;

export const ResultsRowSwiper = styled.div`
  width: 100%;
  min-height: 132px;
`;

export const GallerySliderWrapper = styled.div`
  width: 100%;
  .swiper-slide {
    width: 300px !important;
    height: 200px !important;
    img {
      width: 100% !important;
      height: 200px !important;
      object-fit: cover;
    }
  }
  @media screen and (max-width: 375px) {
    .swiper-slide {
      width: calc(100% - 48px) !important;
    }
  }
`;

export const AlbumItemContainer = styled.div``;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

export const ViewAlbumButton = styled.button`
  position: relative;
  display: flex;
  width: 180px;
  justify-content: space-between;
  transition: 300ms;
  svg {
    transition: 300ms;
  }
  p {
    font-weight: 400;
  }
  &:after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 1px;
    background-color: ${theme.palette.yellow.main};
    content: "";
    transition: 300ms;
  }
  &:hover {
    svg {
      transform: translateX(8px);
    }
    &:after {
      width: 48px;
    }
  }
`;
