import styled from "styled-components";

import { gridTheme } from "@byko/lib-styles";

import type { MirrorProps } from "./interface";

export const SectionWrapper = styled.div<MirrorProps>`
  display: flex;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  flex-direction: ${(props): string => (props.mirrorY ? "row-reverse" : "row")};
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    display: block;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const CardContainer = styled.div`
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    margin: 0;
  }
`;

export const BlockCard = styled.div<MirrorProps>`
  max-width: 600px;
  margin-top: 60px;
  margin-left: ${(props): string => (props.mirrorY ? "min(128px, 30%)" : "unset")};
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    margin-top: none;
    margin-left: unset;
  }
`;

export const PrimaryImageContainer = styled.div<MirrorProps>`
  position: relative;
  left: ${(props): string => (props.mirrorY ? "-20%" : "0")};
  width: 125%;
  height: 630px;
  img {
    width: 100%;
    max-width: 59%;
    max-height: 630px;
    object-fit: cover !important;
  }
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    overflow: hidden;
    width: 100%;
    max-height: 400px;
  }
`;

export const MinicardContainerWrapper = styled.div<MirrorProps>`
  position: relative;
  display: flex;
  max-width: 700px;
  flex-direction: column;
  align-content: flex-start;
  margin-top: 56px;
  gap: 23px;
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const MiniCardContainer = styled.div`
  display: flex;
  width: 100%;
  align-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 545px) {
    flex-wrap: wrap;
  }
`;

export const CardOne = styled.div`
  margin-top: 60px;
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    > * {
      width: 100%;
    }
  }
`;

export const CardTwo = styled.div`
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    width: 100%;
    > * {
      width: 100%;
    }
  }
`;

export const CardThree = styled.div`
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    width: 100%;
    > * {
      width: 100%;
    }
  }
  @media screen and (max-width: 545px) {
    display: none;
  }
`;

export const CardFour = styled.div`
  margin-top: -66px;
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    width: 100%;
    margin-top: 0px;
    > * {
      width: 100%;
    }
  }
  @media screen and (max-width: 545px) {
    display: none;
  }
`;
