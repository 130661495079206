import * as React from "react";

import { CardContentTabs } from "@byko/card-content-tabs";
import { KeyValueFields } from "@byko/card-content-tabs";

import { Container } from "./styles";

import type { ContentSectionFilesProps } from "./interface";

export const ContentSectionFiles = ({ tabCollection }: ContentSectionFilesProps): JSX.Element => {
  return (
    <Container>
      <CardContentTabs tabCollection={tabCollection} />
    </Container>
  );
};

ContentSectionFiles.Item = KeyValueFields;
