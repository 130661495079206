import React from "react";

import { Container, Image } from "./styles";

import type { ImageTileProps } from "./interface";

export const ImageTile = ({ image, alt, size = "large" }: ImageTileProps): JSX.Element => {
  return (
    <Container size={size}>
      <Image alt={alt} src={image} />
    </Container>
  );
};
