import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

import type { BlockProps } from "./interface";

export const Container = styled.div<BlockProps>`
  position: relative;
  display: flex;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  justify-content: ${(props): string => (props.mirrorX ? "flex-end" : "flex-start")};
  padding: 213px 136px;
  margin-right: auto;
  margin-left: auto;
  transition: padding 200ms;

  @media screen and (min-width: ${gridTheme.container.minWidth.xs}px) and (max-width: ${gridTheme.container.maxWidth
      .xs}px) {
    padding: 48px 24px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.sm}px) and (max-width: ${gridTheme.container.maxWidth
      .sm}px) {
    padding: 64px 48px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.md}px) and (max-width: ${gridTheme.container.maxWidth
      .md}px) {
    padding: 80px 56px;
  }
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    padding: 48px 0px;
  }
`;

export const TextCard = styled.div`
  position: relative;
  display: block;
  max-width: 800px;
  padding: 80px 96px 88px;
  background-color: ${theme.palette.white.main};
  filter: drop-shadow(0px 18px 45px rgba(0, 0, 0, 0.09));
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    padding: 48px 32px;
  }
`;

export const PrimaryImageContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: 1616px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  inset: 0;
  img {
    object-fit: cover !important;
  }
`;
