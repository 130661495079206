import * as React from "react";
import dynamic from "next/dynamic";
import { DatoImage } from "@byko/component-dato-image";
import { TextBlockVariantOne } from "@byko/text-block-variant-one";

import {
  BackdropImageContainer,
  Block,
  BlockContainer,
  Container,
  ListContentContainer,
  TextBlockContainer,
  TextRows,
} from "./styles";

import type { ContentSectionVariantFiveProps } from "./interface";

const TextBlockVariantThree = dynamic(() => import("@byko/text-block-variant-three"), { ssr: false });

export const ContentSectionVariantFive = ({
  heading,
  paragraph,
  headingUnderlineColor = "blueLine",
  headingDecoration,
  mirrorY = false,
  introText,
  decorativeImageOne,
  textRows,
  actionButton,
}: ContentSectionVariantFiveProps): JSX.Element => {
  return (
    <Container>
      <BlockContainer>
        <Block>
          <TextBlockContainer mirrorY={mirrorY}>
            <TextBlockVariantOne
              buttonLabel={actionButton?.text}
              buttonLink={actionButton?.link}
              heading={heading}
              headingDecoration={headingDecoration}
              headingUnderlineColor={headingUnderlineColor}
              introText={introText}
              paragraph={paragraph}
            />

            <ListContentContainer>
              <BackdropImageContainer mirrorY={mirrorY}>
                <DatoImage {...decorativeImageOne} />
              </BackdropImageContainer>
              {textRows && (
                <TextRows mirrorY={mirrorY}>
                  {textRows.map((row) => (
                    <TextBlockVariantThree
                      key={row.id}
                      icon={row.icon}
                      mirrorY={mirrorY}
                      number={row.number}
                      paragraph={row.paragraph}
                      title={row.title}
                    />
                  ))}
                </TextRows>
              )}
            </ListContentContainer>
          </TextBlockContainer>
        </Block>
      </BlockContainer>
    </Container>
  );
};
