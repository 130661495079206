import React, { useCallback, useEffect } from "react";
import { useSwiper } from "swiper/react";

import { IconButton } from "@byko/component-buttons";
import { ArrowLeftIcons, ArrowRightIcons } from "@byko/lib-icons";
import { useKeyPress } from "@byko/lib-utils";

import { ActionContainer, ProgressBar, ProgressContainer } from "./styles";

import type { ProgressHandlerProps } from "./interface";

export const ProgressHandler = ({ progress }: ProgressHandlerProps): JSX.Element => {
  const leftPress = useKeyPress("ArrowLeft");
  const rightPress = useKeyPress("ArrowRight");
  const swiper = useSwiper();

  const handleNext = useCallback(() => {
    swiper.slideNext();
  }, [swiper]);

  const handlePrevious = useCallback(() => {
    swiper.slidePrev();
  }, [swiper]);

  useEffect(() => {
    if (leftPress) {
      handlePrevious();
    }
    if (rightPress) {
      handleNext();
    }
  }, [handleNext, handlePrevious, leftPress, rightPress]);
  return (
    <ProgressContainer>
      <ProgressBar status={progress} />
      <ActionContainer>
        <IconButton ariaLabel="Seinasta Mynd" icon={ArrowLeftIcons} onClick={handlePrevious} />
        <IconButton ariaLabel="Næsta Mynd" icon={ArrowRightIcons} onClick={handleNext} />
      </ActionContainer>
    </ProgressContainer>
  );
};
