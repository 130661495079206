import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

import type { ContainerProps } from "./interface";

export const Container = styled.div<ContainerProps>`
  width: ${(props): string => (props.size === "large" ? "452px" : props.size === "medium" ? "336px" : "265px")};
  height: ${(props): string => (props.size === "large" ? "452px" : props.size === "medium" ? "336px" : "265px")};
  padding: ${(props): string => (props.size === "large" ? "20px" : props.size === "medium" ? "16px" : "12px")};
  background-color: ${theme.palette.white.main};
  transition: 300ms;

  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    width: ${(props): string => (props.size === "small" ? "253px" : "336px")};
    height: ${(props): string => (props.size === "small" ? "253px" : "336px")};
    padding: ${(props): string => (props.size === "small" ? "12px" : "16px")};
  }

  @media screen and (max-width: ${gridTheme.breakpoints.xs}px) {
    width: 265px;
    height: 265px;
    padding: 12px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
