/* stylelint-disable named-grid-areas-no-invalid */
import styled from "styled-components";

import { gridTheme } from "@byko/lib-styles";
import { TextBlockVariantOne } from "@byko/text-block-variant-one";

import type { BlockProps } from "./interface";

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  margin-right: auto;
  margin-left: auto;
`;

export const BlockContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Block = styled.div<BlockProps>`
  display: grid;
  width: 100%;
  align-items: center;
  margin-top: -48px;
  grid-gap: 129px;
  grid-template-areas: ${(props): string =>
    props.hasDecorativeImage && props.mirrorY ? `"images textblock"` : `"textblock images"`};
  grid-template-columns: ${(props): string => (props.hasDecorativeImage ? "1fr 1fr" : "none")};

  @media screen and (min-width: ${gridTheme.container.minWidth.xs}px) and (max-width: ${gridTheme.container.maxWidth
      .xs}px) {
    padding: 48px 24px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.sm}px) and (max-width: ${gridTheme.container.maxWidth
      .sm}px) {
    padding: 64px 48px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.md}px) and (max-width: ${gridTheme.container.maxWidth
      .md}px) {
    padding: 80px 56px;
  }
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    margin-top: unset;
    grid-gap: 48px;
    grid-template-areas: ${(props): string =>
      props.hasDecorativeImage && props.mirrorY
        ? `"images"
    "textblock"`
        : `"textblock"
    "images"`};
    grid-template-columns: 1fr;
  }
`;

const DecorativeImageWrapper = styled.div<{ imageCount: number }>`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  max-height: 340px !important;
  span {
    position: relative;
    height: 100% !important;
  }
  img {
    object-fit: cover !important;
  }
`;

export const DecorativeImageAContainer = styled(DecorativeImageWrapper)`
  grid-area: decorativea;
`;

export const DecorativeImageBContainer = styled(DecorativeImageWrapper)`
  grid-area: decorativeb;
`;

export const DecorativeImageCContainer = styled(DecorativeImageWrapper)`
  grid-area: decorativec;
`;

export const TextBlock = styled(TextBlockVariantOne)`
  grid-area: textblock;
`;

export const DecorativeImageContainerWrapper = styled.div<{ imageCount: number; mirrorY: boolean | null }>`
  display: grid;
  height: 100%;
  max-height: 688px;
  align-items: center;
  grid-area: images;
  grid-gap: 8px;
  grid-template-areas: ${(props): string =>
    props.imageCount === 2
      ? `"decorativea"
        "decorativeb"`
      : props.imageCount === 3
      ? `"decorativea decorativea"
        "decorativeb decorativec"`
      : `"decorativea"`};
  grid-template-rows: ${(props): string => (props.imageCount === 2 || props.imageCount === 3 ? "2fr" : "1fr")};
`;
