import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

import type { ContainerProps } from "./interface";

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: grid;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  margin-right: auto;
  margin-left: auto;
  grid-template-areas: ${(props): string => (props.mirrorY ? `"primary card"` : `"card primary"`)};
  grid-template-columns: ${(props): string => (props.mirrorY ? "1fr 1.05fr" : "1.05fr 1fr")};
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    grid-template-areas: ${(props): string =>
      props.mirrorY
        ? `"primary"
      "card"`
        : `"card"
        "primary"`};
    grid-template-columns: 1fr;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 64px;
  grid-area: card;
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    margin: 0;
  }
`;

export const BlockCard = styled.div`
  width: 100%;
  padding: 80px 96px;
  background-color: ${theme.palette.white.main};
  box-shadow: 0px 18px 45px ${theme.palette.shadow.main};

  @media screen and (min-width: ${gridTheme.container.minWidth.xs}px) and (max-width: ${gridTheme.container.maxWidth
      .xs}px) {
    padding: 48px 24px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.sm}px) and (max-width: ${gridTheme.container.maxWidth
      .sm}px) {
    padding: 64px 48px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.s}px) and (max-width: ${gridTheme.container.maxWidth
      .s}px) {
    padding: 80px 56px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.md}px) and (max-width: ${gridTheme.container.maxWidth
      .md}px) {
    padding: 80px 56px;
  }

  @media screen and (max-width: 345px) {
    padding: 24px 16px;
  }
`;

export const PrimaryImageContainer = styled.div`
  position: relative;
  width: 100%;
  box-shadow: 0px 18px 45px rgba(0, 0, 0, 0.09);
  grid-area: primary;
  img {
    object-fit: cover !important;
  }
  @media screen and (max-width: 970px) {
    min-height: 60vw;
  }
  @media screen and (max-width: 480px) {
    min-height: 100vw;
  }
`;
