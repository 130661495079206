import styled from "styled-components";

import { theme } from "@byko/lib-styles";

export const ActionContainer = styled.div`
  display: flex;
  width: 120px;
  justify-content: space-between;
  gap: 8px;
`;

export const ProgressContainer = styled.div`
  position: relative;
  z-index: 3000001;
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  justify-content: center;
  padding-right: 140px;
  padding-left: 140px;
  margin-top: 42px;
  margin-bottom: 32px;
  gap: 16px;
`;

export const ProgressBar = styled.div<{ status: string }>`
  position: relative;
  width: 100%;
  height: 4px;
  background-color: ${theme.palette.gray[20]};
  outline: 1px solid ${theme.palette.white.main};
  &:after {
    position: absolute;
    width: ${(props): string => props.status};
    height: 100%;
    background-color: ${theme.palette.blue.main};
    content: "";
    transition: 300ms;
  }
`;
