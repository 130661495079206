import { decode } from "blurhash";

import { parsePixels } from "./parse-pixels";

export const blurHashToDataURL = (hash: string | undefined): string | undefined => {
  if (!hash) return undefined;

  const pixels = decode(hash, 32, 32);
  const dataURL = parsePixels(pixels, 32, 32);
  return dataURL;
};
