import styled from "styled-components";

import { gridTheme } from "@byko/lib-styles";

import type { ContainerProps } from "./interface";

export const Container = styled.div<ContainerProps>`
  display: flex;
  max-width: ${gridTheme.maxContentWidth}px;
  flex-wrap: wrap;
  justify-content: ${(props): string => (props.variant === "left-aligned" ? "flex-start" : "flex-end")};
  margin-right: auto;
  margin-left: auto;
  column-gap: ${(props): string =>
    props.variant === "left-aligned"
      ? "16px"
      : props.size === "large"
      ? "85px"
      : props.size === "medium"
      ? "74px"
      : "56px"};
  row-gap: ${(props): string =>
    props.variant === "right-aligned"
      ? "16px"
      : props.size === "large"
      ? "85px"
      : props.size === "medium"
      ? "74px"
      : "56px"};
`;
