import * as React from "react";

import { DatoImage } from "@byko/component-dato-image";

import {
  Block,
  BlockContainer,
  Container,
  DecorativeImageAContainer,
  DecorativeImageBContainer,
  DecorativeImageCContainer,
  DecorativeImageContainerWrapper,
  TextBlock,
} from "./styles";

import type { ContentSectionVariantThreeProps } from "./interface";

export const ContentSectionVariantThree = ({
  heading,
  paragraph,
  headingUnderlineColor = "blueLine",
  headingDecoration,
  maxColumns = 1,
  mirrorY = false,
  decorativeImageOne,
  decorativeImageTwo,
  decorativeImageThree,
  introText,
  actionButton,
}: ContentSectionVariantThreeProps): JSX.Element => {
  const decorativeImageCount =
    Number(Boolean(decorativeImageOne)) + Number(Boolean(decorativeImageTwo)) + Number(Boolean(decorativeImageThree));
  return (
    <Container>
      <BlockContainer>
        <Block hasDecorativeImage={decorativeImageCount > 0} mirrorY={mirrorY}>
          <TextBlock
            buttonLabel={actionButton?.text}
            buttonLink={actionButton?.link}
            heading={heading}
            headingDecoration={headingDecoration}
            headingUnderlineColor={headingUnderlineColor}
            introText={introText}
            maxColumns={maxColumns}
            paragraph={paragraph}
          />

          {decorativeImageCount > 0 && (
            <DecorativeImageContainerWrapper imageCount={decorativeImageCount} mirrorY={mirrorY}>
              {decorativeImageOne ? (
                <DecorativeImageAContainer imageCount={decorativeImageCount}>
                  <DatoImage {...decorativeImageOne} />
                </DecorativeImageAContainer>
              ) : null}
              {decorativeImageTwo ? (
                <DecorativeImageBContainer imageCount={decorativeImageCount}>
                  <DatoImage {...decorativeImageTwo} />
                </DecorativeImageBContainer>
              ) : null}
              {decorativeImageThree ? (
                <DecorativeImageCContainer imageCount={decorativeImageCount}>
                  <DatoImage {...decorativeImageThree} />
                </DecorativeImageCContainer>
              ) : null}
            </DecorativeImageContainerWrapper>
          )}
        </Block>
      </BlockContainer>
    </Container>
  );
};
