import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const BookingContainer = styled.div`
  height: 100%;
  iframe {
    overflow: hidden;
    height: 100%;
    border: none;
    outline: none;
  }
`;
