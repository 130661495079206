import * as React from "react";

import { BlockImageLink } from "@byko/block-image-link";
import { DatoImage } from "@byko/component-dato-image";
import { gridTheme } from "@byko/lib-styles";
import { useWindowSize } from "@byko/lib-styles";
import { TextBlockVariantOne } from "@byko/text-block-variant-one";

import {
  BlockCard,
  CardFour,
  CardOne,
  CardThree,
  CardTwo,
  Container,
  MiniCardContainer,
  MinicardContainerWrapper,
  PrimaryImageContainer,
  SectionWrapper,
} from "./styles";

import type { ContentSectionVariantOneProps } from "./interface";

export const ContentSectionVariantSeven = ({
  heading,
  paragraph,
  headingUnderlineColor = "blueLine",
  headingDecoration,
  introText,
  maxColumns = 1,
  actionButton,
  cardOneLabel,
  cardOneImage,
  cardOneLink,
  cardTwoLabel,
  cardTwoImage,
  cardTwoLink,
  cardThreeLabel,
  cardThreeImage,
  cardThreeLink,
  cardFourLabel,
  cardFourImage,
  cardFourLink,
  mirrorY = false,
  primaryImage,
}: ContentSectionVariantOneProps): JSX.Element => {
  const { width } = useWindowSize();
  return (
    <SectionWrapper mirrorY={mirrorY}>
      <Container>
        <PrimaryImageContainer mirrorY={mirrorY}>
          <DatoImage {...primaryImage} />
        </PrimaryImageContainer>
        <BlockCard mirrorY={mirrorY}>
          <TextBlockVariantOne
            buttonLabel={actionButton?.text}
            buttonLink={actionButton?.link}
            heading={heading}
            headingDecoration={headingDecoration}
            headingUnderlineColor={headingUnderlineColor}
            introText={introText}
            maxColumns={maxColumns}
            paragraph={paragraph}
          />
        </BlockCard>
      </Container>
      <MinicardContainerWrapper mirrorY={mirrorY}>
        <MiniCardContainer>
          {cardOneImage && (
            <CardOne>
              <BlockImageLink
                image={cardOneImage}
                label={cardOneLabel ?? ""}
                link={cardOneLink ?? "#"}
                mirrorX={true}
                size={
                  width < gridTheme.breakpoints.sm ? "small" : width < gridTheme.breakpoints.md ? "large" : "medium"
                }
              />
            </CardOne>
          )}

          {cardTwoImage && (
            <CardTwo>
              <BlockImageLink
                image={cardTwoImage}
                label={cardTwoLabel ?? ""}
                link={cardTwoLink ?? "#"}
                mirrorX={true}
                size={
                  width < gridTheme.breakpoints.sm ? "small" : width < gridTheme.breakpoints.md ? "large" : "medium"
                }
              />
            </CardTwo>
          )}
        </MiniCardContainer>

        <MiniCardContainer>
          {cardThreeImage && (
            <CardThree>
              <BlockImageLink
                image={cardThreeImage}
                label={cardThreeLabel ?? ""}
                link={cardThreeLink ?? "#"}
                mirrorX={true}
                size={
                  width < gridTheme.breakpoints.sm ? "small" : width < gridTheme.breakpoints.md ? "large" : "medium"
                }
              />
            </CardThree>
          )}

          {cardFourImage && (
            <CardFour>
              <BlockImageLink
                image={cardFourImage}
                label={cardFourLabel ?? ""}
                link={cardFourLink ?? "#"}
                mirrorX={true}
                size={
                  width < gridTheme.breakpoints.sm ? "small" : width < gridTheme.breakpoints.md ? "large" : "medium"
                }
              />
            </CardFour>
          )}
        </MiniCardContainer>
      </MinicardContainerWrapper>
    </SectionWrapper>
  );
};
