import styled from "styled-components";

import { gridTheme } from "@byko/lib-styles";

import type { BlockProps } from "./interface";

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  margin-right: auto;
  margin-bottom: -48px;
  margin-left: auto;
`;

export const BlockContainer = styled.div`
  display: grid;
  align-items: center;
`;

export const Block = styled.div`
  @media screen and (min-width: ${gridTheme.container.minWidth.xs}px) and (max-width: ${gridTheme.container.maxWidth
      .xs}px) {
    padding: 48px 0px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.sm}px) and (max-width: ${gridTheme.container.maxWidth
      .sm}px) {
    padding: 64px 0px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.md}px) and (max-width: ${gridTheme.container.maxWidth
      .md}px) {
    padding: 80px 0px;
  }
  @media screen and (max-width: 375px) {
    padding: 48px 0px;
  }
`;

export const TextBlockContainer = styled.div<BlockProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props): string => (props.mirrorY ? "flex-end" : "flex-start")};
  padding-top: 48px;
  padding-bottom: 48px;
  @media screen and (max-width: ${gridTheme.breakpoints.md}px) {
    width: 100%;
  }
`;

export const ListContentContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1104px;
`;

export const TextRows = styled.div<BlockProps>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: ${(props): string => (props.mirrorY ? "flex-start" : "flex-end")};
  justify-content: center;
  padding-top: 64px;
  padding-bottom: 64px;
  row-gap: 16px;
  transition: 300ms;
  @media screen and (max-width: ${gridTheme.breakpoints.md}px) {
    padding-right: 32px;
    padding-left: 32px;
  }
  @media screen and (max-width: 375px) {
    padding-right: 0px;
    padding-left: 0px;
  }
`;

export const BackdropImageContainer = styled.div<BlockProps>`
  position: absolute;
  z-index: -1;
  ${(props): string => (props.mirrorY ? "right: 0px" : "left: 0px")};
  width: 63%;
  height: 100%;
  img {
    object-fit: cover !important;
  }
  @media screen and (max-width: 1389px) {
    width: 70%;
  }
  @media screen and (max-width: 970px) {
    width: 100%;
    margin-bottom: -180px;
  }
  @media screen and (max-width: 375px) {
    img {
      display: none;
    }
  }
`;
