import * as React from "react";

import { theme } from "@byko/lib-styles";
import { TextBlockVariantOne } from "@byko/text-block-variant-one";

import { ContentContainer, ItemSection, SectionContent, SectionTitle } from "./styles";

import type { TextBlockVariantTwoProps } from "./interface";

export const TextBlockVariantTwo = ({
  heading,
  introText,
  paragraph,
  headingUnderlineColor = "blueLine",
  headingDecoration,
  itemSection,
  buttonLabel,
  buttonLink,
}: TextBlockVariantTwoProps): JSX.Element => {
  return (
    <ContentContainer>
      <TextBlockVariantOne
        buttonLabel={buttonLabel}
        buttonLink={buttonLink}
        heading={heading}
        headingDecoration={headingDecoration}
        headingUnderlineColor={headingUnderlineColor}
        introText={introText}
        maxColumns={1}
        paragraph={paragraph}
      />

      {itemSection ? (
        <ItemSection>
          <SectionTitle textColor={theme.palette.blue.dark} uppercase={true}>
            {itemSection.title}
          </SectionTitle>
          {itemSection.items.map(({ id, content }) => {
            return <SectionContent key={id}>{content}</SectionContent>;
          })}
        </ItemSection>
      ) : null}
    </ContentContainer>
  );
};
