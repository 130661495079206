import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { IconButton } from "@byko/component-buttons";
import { PageContainer } from "@byko/component-page-container";
import { Slider } from "@byko/component-slider";
import { PMedium } from "@byko/component-typography";
import { ArrowRightIcons, CancelSmallIcons } from "@byko/lib-icons";
import { theme, useWindowSize } from "@byko/lib-styles";
import "swiper/css";

import { useKeyPress } from "@byko/lib-utils";

import { ProgressHandler } from "./components";
import {
  AlbumItemContainer,
  AlbumItemContainerBig,
  CloseButtonContainer,
  Container,
  GallerySliderWrapper,
  HeaderContainer,
  ImageBlock,
  OverlayContainer,
  OverlayOuter,
  ResultsRowSwiper,
  SliderImage,
  SwiperContainer,
  ViewAlbumButton,
} from "./styles";

import type { ContentSectionPhotoAlbumProps } from "./interface";
import type { Swiper as Swiperclass } from "swiper/types";

export const ContentSectionPhotoAlbum = ({ photos }: ContentSectionPhotoAlbumProps): JSX.Element => {
  const { activeBreakPoint } = useWindowSize();
  const escapePress = useKeyPress("Escape");
  const [overlayActive, setOverlayActive] = useState<boolean>(false);
  const [fadeInClass, setFadeInClass] = useState<string>("");
  const [progress, setProgress] = useState<string>("0%");

  const imageSlides = useMemo((): JSX.Element[] => {
    const images: JSX.Element[] = photos.map((item) => {
      return (
        <AlbumItemContainer key={item.jpeg}>
          <ImageBlock alt={item.alt} src={item.jpeg} />
        </AlbumItemContainer>
      );
    });

    return images;
  }, [photos]);

  const selectImage = useCallback(() => {
    setOverlayActive(true);
  }, []);

  const closePhotoAlbum = useCallback(() => {
    setOverlayActive(false);
  }, []);

  useEffect(() => {
    if (escapePress) {
      closePhotoAlbum();
    }
  }, [closePhotoAlbum, escapePress]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const element = document.querySelector("body");
      if (overlayActive) {
        element?.classList.add("no-scroll");
      } else {
        element?.classList.remove("no-scroll");
      }
    }
    setTimeout(() => {
      setFadeInClass(overlayActive ? "fade-in" : "");
    }, 100);
  }, [overlayActive]);

  const handleChange = useCallback(
    (swiper: Swiperclass) => {
      const slidesPerView = 1;
      const localProgress = ((slidesPerView + swiper.activeIndex) / photos.length) * 100;
      setProgress(localProgress <= 100 ? `${localProgress}%` : "100%");
    },
    [photos.length],
  );

  return (
    <>
      <Container>
        <ResultsRowSwiper>
          <PageContainer flexDirection="column" offsetNav={false}>
            <HeaderContainer>
              <ViewAlbumButton onClick={selectImage}>
                <ArrowRightIcons iconColor={theme.palette.blue.main} size={24} />
                <PMedium>Skoða myndaalbúm</PMedium>
              </ViewAlbumButton>
            </HeaderContainer>
          </PageContainer>
        </ResultsRowSwiper>
      </Container>
      <GallerySliderWrapper>
        <Slider autoPad={true} paginationGutter={true} slideCollection={imageSlides} onClick={selectImage} />
      </GallerySliderWrapper>
      {overlayActive && (
        <>
          <OverlayOuter className={fadeInClass} onClick={closePhotoAlbum} />
          <OverlayContainer active={overlayActive} className={fadeInClass}>
            <SwiperContainer>
              <Swiper onAfterInit={handleChange} onResize={handleChange} onTransitionStart={handleChange}>
                {photos.map((slideContent) => {
                  return (
                    <SwiperSlide key={slideContent.jpeg}>
                      <AlbumItemContainerBig>
                        <SliderImage src={slideContent.jpeg} />
                      </AlbumItemContainerBig>
                    </SwiperSlide>
                  );
                })}
                <ProgressHandler progress={progress} />
                <CloseButtonContainer>
                  <IconButton
                    ariaLabel="Loka"
                    buttonColor="blueButton"
                    icon={CancelSmallIcons}
                    small={["xs", "s", "sm"].includes(activeBreakPoint)}
                    onClick={closePhotoAlbum}
                  />
                </CloseButtonContainer>
              </Swiper>
            </SwiperContainer>
          </OverlayContainer>
        </>
      )}
    </>
  );
};
