import * as React from "react";

import { EyebrowLarge } from "@byko/component-typography";
import { LongArrowSideIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import { Container, DescriptionBar, Image } from "./styles";

import type { BlockImageLinkProps } from "./interface";

export const BlockImageLink = ({
  image,
  label,
  mirrorX,
  size,
  noIcon,
  stretch,
  tabIndex,
  border = false,
  link = "#",
}: BlockImageLinkProps): JSX.Element => {
  return (
    <Container
      border={border}
      href={link}
      mirrorX={mirrorX ?? false}
      noIcon={noIcon ?? false}
      size={size ?? "large"}
      stretch={stretch ?? false}
      tabIndex={tabIndex ?? 0}
    >
      <Image alt={label} custom={true} jpeg={image} />
      <DescriptionBar>
        <EyebrowLarge textColor={theme.palette.blue.dark} uppercase={true}>
          {label}
        </EyebrowLarge>
        {Boolean(noIcon) === false && <LongArrowSideIcons size={24} />}
      </DescriptionBar>
    </Container>
  );
};
