import { generatePng } from "./generate-png";
import { getPngArray } from "./get-png-array";

export const parsePixels = (pixels: Uint8ClampedArray, width: number, height: number): string => {
  const pixelsString = [...pixels].map((byte) => String.fromCharCode(byte)).join("");
  const pngString = generatePng(width, height, pixelsString);
  const dataURL =
    typeof Buffer !== "undefined" ? Buffer.from(getPngArray(pngString)).toString("base64") : btoa(pngString);
  return `data:image/png;base64,${dataURL}`;
};
