import React, { useEffect, useState } from "react";

import { theme } from "@byko/lib-styles";

import { BookingContainer, Container } from "./styles";
import { SimplybookWidget } from "./widget";

export const BookingSection = (): JSX.Element | null => {
  const [injectedBookingScript, setInjectedBookingScript] = useState<boolean>(false);

  useEffect(() => {
    if (!injectedBookingScript && document.getElementById("simply-book-widget-script") === null) {
      new SimplybookWidget({
        widgetType: "iframe",
        url: "https://byko.simplybook.it",
        theme: "emeri",
        containerID: "booking-widget-container",
        themeSettings: {
          timeline_hide_unavailable: "1",
          sb_base_color: theme.palette.blue.main,
          hide_past_days: "0",
          timeline_show_end_time: "0",
          timeline_modern_display: "as_slots",
          display_item_mode: "block",
          sb_review_image: "",
          dark_font_color: theme.palette.blue.dark,
          light_font_color: theme.palette.white.main,
          background_color: "red",
          sb_company_label_color: theme.palette.white.main,
          hide_img_mode: "0",
          show_sidebar: "1",
          sb_busy: theme.palette.gray[40],
          sb_available: "#d3e0f1",
        },
        timeline: "modern_week",
        datepicker: "top_calendar",
        is_rtl: false,
        app_config: { allow_switch_to_ada: 0, predefined: [] },
      });
      setInjectedBookingScript(true);
    }
  }, [injectedBookingScript]);

  return (
    <Container>
      <BookingContainer id="booking-widget-container" />
    </Container>
  );
};
