import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";
import { TextBlockVariantTwo } from "@byko/text-block-variant-two";

import type { BlockProps, DecorativeImageContainerProps } from "./interface";

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  margin-right: auto;
  margin-left: auto;
`;

export const Block = styled.div<BlockProps>`
  display: grid;
  align-items: center;
  grid-gap: 129px;
  grid-template-areas: ${(props): string => (props.mirrorY ? `"images textblock"` : `"textblock images"`)};
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: ${gridTheme.breakpoints.md}px) {
    grid-gap: 48px;
    grid-template-areas: ${(props): string =>
      props.mirrorY
        ? `"images"
    "textblock"`
        : `"textblock"
    "images"`};
    grid-template-columns: 1fr;
  }
`;

export const TextBlockContainer = styled.div`
  padding-top: 48px;
  padding-bottom: 48px;
  @media screen and (max-width: ${gridTheme.breakpoints.md}px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const TextBlock = styled(TextBlockVariantTwo)`
  grid-area: textblock;
`;

export const DecorativeImageContainerWrapper = styled.div<DecorativeImageContainerProps>`
  display: flex;
  height: 100%;
  flex-direction: column;
  row-gap: 16px;
  @media screen and (max-width: ${gridTheme.breakpoints.md}px) {
    padding-top: ${(props): string =>
      !props.mirrorY && props.tagCount === 2 ? "451px" : !props.mirrorY && props.tagCount === 1 ? "160px" : "0px"};
    padding-bottom: ${(props): string =>
      props.mirrorY && props.tagCount === 2 ? "451px" : props.mirrorY && props.tagCount === 1 ? "160px" : "0px"};
  }
`;

export const DecorativeImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  img {
    object-fit: cover !important;
  }
  @media screen and (max-width: ${gridTheme.breakpoints.md}px) {
    height: 480px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  grid-area: images;
`;

export const TaggedImageOverlay = styled.div<BlockProps>`
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: ${(props): string => (props.mirrorY ? "flex-end;" : "flex-start;")};
  justify-content: center;
  inset-block: 0;
  row-gap: 16px;
  transform: translate3d(${(props): string => (props.mirrorY ? "80px" : "-80px")}, 0, 0);
  @media screen and (max-width: ${gridTheme.breakpoints.md}px) {
    top: 0;
    bottom: 0;
    left: 50%;
    align-items: center;
    justify-content: ${(props): string => (props.mirrorY ? "flex-end" : "flex-start")};
    transform: translate3d(-50%, 0, 0);
  }
`;

export const TaggedImageContainer = styled.div`
  position: relative;
  z-index: 2;
  overflow: hidden;
  width: fit-content;
`;

export const TaggedImage = styled.img`
  width: 288px;
  height: 266px;
  object-fit: cover;
  @media screen and (max-width: 970px) {
    width: min(288px, 100%);
    height: min(266px, 100%);
  }
`;

export const Tag = styled.div`
  display: flex;
  width: 288px;
  height: 56px;
  align-items: center;
  justify-content: center;
  background-color: ${theme.palette.white.main};
  transform: translate3d(0, -6px, 0);
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    width: 217px;
  }
`;
