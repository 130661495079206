import styled from "styled-components";

import { gridTheme } from "@byko/lib-styles";
import { TextBlockVariantOne } from "@byko/text-block-variant-one";

import type { BlockProps } from "./interface";

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  margin-right: auto;
  margin-left: auto;
`;

export const Block = styled.div<BlockProps>`
  display: grid;
  width: 100%;
  align-items: center;
  grid-column-gap: ${(props): string => (props.mirrorX ? "5px " : "129px")};
  grid-template-areas: ${(props): string =>
    props.mirrorX
      ? `"textblock textblock"
    "cards cards"`
      : `"textblock textblock"
    "cards cards"`};
  grid-template-columns: ${(props): string => (props.mirrorX ? "6fr 4fr" : "4fr 6fr")};

  @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    grid-gap: 10px;
    grid-template-areas:
      "textblock"
      "cards";
    grid-template-columns: 1fr;
  }
`;

export const TextBlockContainer = styled.div<BlockProps>``;

export const TextBlock = styled(TextBlockVariantOne)`
  grid-area: textblock;
`;

export const CardsImageLinkWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1000px;
  flex-flow: wrap;
  margin-top: 16px;
  margin-bottom: 2rem;
  gap: 16px;
  grid-area: cards;

  @media screen and (min-width: ${gridTheme.breakpoints.sm}px) {
    .block-image-link {
      :hover {
        filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.09));
      }
    }
  }
  @media screen and (max-width: 1079px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 469px) {
    justify-content: center;
  }
`;
