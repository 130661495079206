import React, { useCallback, useEffect } from "react";

import type { HtmlBlock } from "@byko/lib-api-dato-client";
import styled from "styled-components";

export const IframeContainer = styled.div`
  width: 100%;
  height: 100%;
  iframe {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
  }
`;

export const ContentSectionHtmlBlock = ({ content }: { content: HtmlBlock }): JSX.Element => {
  const iframeRef = React.createRef<HTMLIFrameElement>();
  const FRAME_PADDING = 30;

  const setFrameHeight = useCallback(() => {
    if (typeof window === "undefined") return;

    const element = iframeRef.current;
    if (element && element.contentWindow && element.contentDocument) {
      // Create and add resize observer
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const height = entry.target.scrollHeight;
          element.style.height = `${height + FRAME_PADDING}px`;
        }
      });

      // Observe the iframe's document body
      if (element.contentDocument.body) {
        resizeObserver.observe(element.contentDocument.body);

        // Initial height set
        element.style.height = `${element.contentDocument.body.scrollHeight}px`;
      }

      // Cleanup observer when iframe changes
      return () => resizeObserver.disconnect();
    }
    return undefined;
  }, [iframeRef]);

  useEffect(() => {
    setFrameHeight();
  }, [setFrameHeight]);

  return (
    <IframeContainer>
      <iframe ref={iframeRef} srcDoc={content.html} title={"custom"} />
    </IframeContainer>
  );
};
