import styled from "styled-components";

import { SubtitleBold } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";

export const ContentContainer = styled.div``;
export const ItemSection = styled.div``;
export const SectionTitle = styled(SubtitleBold)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const SectionContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-left: 36px;
  &::before {
    position: absolute;
    top: 16px;
    bottom: 0;
    left: 0;
    width: 12px;
    height: 12px;
    background-color: ${theme.palette.blue.main};
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    content: "";
  }
`;

export const SBBackdrop = styled.div`
  padding: 48px;
  background-color: ${theme.palette.white.main};
`;
