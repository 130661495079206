import * as React from "react";

import { BlockImageLink } from "@byko/block-image-link";

import { Block, CardsImageLinkWrapper, Container, TextBlock, TextBlockContainer } from "./styles";

import type { ContentSectionNineProps } from "./interface";

export const ContentSectionNine = ({
  heading,
  paragraph,
  cards,
  headingUnderlineColor = "blueLine",
  headingDecoration,
  mirrorX = false,
  introText,
  actionButton,
}: ContentSectionNineProps): JSX.Element => {
  return (
    <Container>
      <Block mirrorX={mirrorX}>
        <TextBlockContainer mirrorX={mirrorX}>
          <TextBlock
            buttonLabel={actionButton?.text}
            buttonLink={actionButton?.link}
            heading={heading}
            headingDecoration={headingDecoration}
            headingUnderlineColor={headingUnderlineColor}
            introText={introText}
            paragraph={paragraph}
          />
        </TextBlockContainer>
        <CardsImageLinkWrapper>
          {cards.map((card) => (
            <BlockImageLink
              key={card.label}
              border={true}
              image={card.image}
              label={card.label}
              link={card.link}
              mirrorX={true}
              size="large"
            />
          ))}
        </CardsImageLinkWrapper>
      </Block>
    </Container>
  );
};
