import React, { useMemo } from "react";

import { DatoImage } from "@byko/component-dato-image";
import { TextBlockVariantOne } from "@byko/text-block-variant-one";

import {
  Block,
  Container,
  DecorativeImageContainer,
  ImageContainer,
  Images,
  SmallImageContainer,
  SmallImageOverlay,
  TextBlockContainer,
} from "./styles";

import type { ContentSectionEightProps } from "./interface";

export const ContentSectionEight = ({
  heading,
  paragraph,
  headingUnderlineColor = "blueLine",
  headingDecoration,
  mirrorX = false,
  introText,
  decorativeImageOne,
  smallImageOne,
  smallImageTwo,
  actionButton,
}: ContentSectionEightProps): JSX.Element => {
  const hasSmallImage = useMemo(() => {
    return smallImageOne !== undefined || smallImageTwo !== undefined;
  }, [smallImageOne, smallImageTwo]);

  return (
    <Container>
      <Block mirrorX={mirrorX}>
        <TextBlockContainer hasSmallImage={hasSmallImage} mirrorX={mirrorX}>
          <TextBlockVariantOne
            buttonLabel={actionButton?.text}
            buttonLink={actionButton?.link}
            heading={heading}
            headingDecoration={headingDecoration}
            headingUnderlineColor={headingUnderlineColor}
            introText={introText}
            paragraph={paragraph}
          />
        </TextBlockContainer>
        <Images>
          <ImageContainer mirrorX={mirrorX}>
            <DecorativeImageContainer mirrorX={mirrorX}>
              <DatoImage {...decorativeImageOne} />
            </DecorativeImageContainer>
          </ImageContainer>
          <SmallImageOverlay mirrorX={mirrorX}>
            {smallImageOne && (
              <SmallImageContainer>
                <DatoImage {...smallImageOne} />
              </SmallImageContainer>
            )}

            {smallImageTwo && (
              <SmallImageContainer>
                <DatoImage {...smallImageTwo} />
              </SmallImageContainer>
            )}
          </SmallImageOverlay>
        </Images>
      </Block>
    </Container>
  );
};
