import styled from "styled-components";

export const HIDE_CLASSNAME = "hide";
export const SHOW_CLASSNAME = "show";

export const DatoImg = styled.img`
  position: absolute;
  display: block;
  overflow: hidden;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: auto;
  inset: 0px;
  object-fit: cover !important;
  text-indent: -200%;
  white-space: nowrap;
  &:-moz-loading {
    visibility: hidden;
  }
`;

export const DatoInnerContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  background: none;
  opacity: 1;
`;

export const DatoImageComponent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
