import styled from "styled-components";

import { DatoImage } from "@byko/component-dato-image";
import { theme } from "@byko/lib-styles";

import type { ContainerProps } from "./interface";

export const DescriptionBar = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  align-items: center;
  padding-right: 24px;
  padding-left: 24px;
  border: 1px solid ${theme.palette.gray[10]};
  background-color: ${theme.palette.white.main};
  p {
    overflow: hidden;
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Image = styled(DatoImage)`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Container = styled.a<ContainerProps>`
  position: relative;
  display: flex;
  overflow: hidden;
  width: ${(props): string =>
    props.size === "large"
      ? "288px"
      : props.size === "medium"
      ? "296px"
      : props.size === "x-small"
      ? "200px"
      : "288px"};
  height: ${(props): string =>
    props.size === "large"
      ? "266px"
      : props.size === "medium"
      ? "376px"
      : props.size === "x-small"
      ? "220px"
      : "321px"};
  max-height: 100%;
  align-items: ${(props): string => (props.mirrorX ? "flex-end" : "flex-start")};
  border: ${(props): string => (props.border ? `1px solid ${theme.palette.gray[10]}` : "none")};
  transition: 300ms;
  &:hover {
    box-shadow: 0px 18px 45px ${theme.palette.shadow.main};
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 469px) {
    width: 271px;
  }
`;
