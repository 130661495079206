import React from "react";

import { ImageTile } from "@byko/component-image-tile";

import { Container } from "./styles";

import type { ContentSectionImageTilesProps } from "./interface";

export const ContentSectionImageTiles = ({ tiles, variant, size }: ContentSectionImageTilesProps): JSX.Element => {
  return (
    <Container variant={variant}>
      {tiles.map(({ alt, image, id }) => {
        return <ImageTile key={id} alt={alt} image={image} size={size} />;
      })}
    </Container>
  );
};
