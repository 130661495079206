import React, { useMemo } from "react";

import { getBykoConfig, useIsClient } from "@byko/lib-utils";
import { blurHashToDataURL } from "@byko/util-blurhash";

import { DatoImageComponent, DatoImg, DatoInnerContainer } from "./style";

import type { Props } from "./interface";

const EMPTYIMAGE = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
const SIZES = [640, 750, 828, 1080, 1200, 1920, 2048, 3840];
const SMALLESTIMAGE = SIZES[0] as number;

export const imageURL = ({
  url,
  width,
  webp = false,
}: {
  url: string | null;
  width: number;
  webp?: boolean | undefined;
}): string => {
  if (!url) {
    return EMPTYIMAGE;
  }
  const host = getBykoConfig("IMAGES_API_URL");
  if (webp) {
    return `${host}/?width=${width}&format=webp&url=${url}`;
  }
  return `${host}/?width=${width}&url=${url}`;
};

export const ImageSource = ({
  url,
  width,
  minWidth,
}: {
  url: string;
  width: number;
  minWidth: number | null;
}): JSX.Element => {
  if (minWidth == null) {
    return (
      <>
        <source
          key={`${width}.webp`}
          media={`(max-width: ${width - 1}px)`}
          srcSet={imageURL({ url, width, webp: true })}
        />
        <source key={width} media={`(max-width: ${width - 1}px)`} srcSet={imageURL({ url, width })} />
      </>
    );
  }
  return (
    <>
      <source
        key={`${width}.webp`}
        media={`(min-width: ${minWidth}px)`}
        srcSet={imageURL({ url, width, webp: true })}
      />
      <source key={width} media={`(min-width: ${minWidth}px)`} srcSet={imageURL({ url, width })} />
    </>
  );
};

export const ImageSources = ({ url }: { url: string }): JSX.Element => {
  return (
    <>
      {SIZES.map((e, i, arr) => {
        const minWidth = arr[i - 1] ?? null;
        return <ImageSource key={e} minWidth={minWidth} url={url} width={e} />;
      })}
    </>
  );
};

export const DatoImage = ({
  blurHash,
  alt = "",
  jpeg,
  className,
  objectPosition,
  custom,
  priority = false,
}: Omit<Props, "blurHash" | "alt" | "width" | "height" | "focalPoint"> & {
  alt?: string | undefined;
  width?: number | undefined;
  height?: number | undefined;
  focalPoint?: {
    x: number;
    y: number;
  };
  custom?: boolean;
  blurHash?: string | undefined;
  priority?: boolean | undefined;
  className?: string | undefined;
}): JSX.Element => {
  const isClient = useIsClient();
  const style = useMemo(() => {
    if (!isClient) {
      if (custom) {
        return {};
      }
      return {
        objectPosition: objectPosition ?? "center center",
        backgroundSize: "cover",
      };
    }
    try {
      const url = blurHashToDataURL(blurHash);
      const background = blurHash
        ? {
            backgroundImage: `url(${url})`,
          }
        : {};
      if (custom) {
        return background;
      }
      return {
        objectPosition: objectPosition ?? "center center",
        backgroundSize: "cover",
        ...background,
      };
    } catch (e) {
      return {
        objectPosition: objectPosition ?? "center center",
      };
    }
  }, [isClient, custom, objectPosition, blurHash]);
  if (custom) {
    return (
      <picture className={className}>
        {jpeg ? <ImageSources url={jpeg} /> : null}
        <img
          alt={alt}
          className={className}
          loading={priority ? "eager" : "lazy"}
          src={blurHash ?? EMPTYIMAGE}
          style={style}
        />
      </picture>
    );
  }
  return (
    <DatoImageComponent>
      <DatoInnerContainer>
        <picture>
          {jpeg ? <ImageSources url={jpeg} /> : null}
          <DatoImg
            alt={alt}
            className={className}
            loading={priority ? "eager" : "lazy"}
            src={imageURL({ width: SMALLESTIMAGE, url: jpeg })}
            style={style}
          />
        </picture>
      </DatoInnerContainer>
    </DatoImageComponent>
  );
};
